 <template>
  <div class="footer_wrap">
    <!-- Footer Start -->
    <div class="flex-grow-1"></div>
    <div>
      <div
        class="
          footer-bottom
          border-top
          py-2
          d-flex
          flex-column flex-sm-row
          align-items-center
          text-center
        "
      >
        <div class="d-flex align-items-center w-100">
              <p class="m-0 w-100 text-10">All rights reserved &copy; <span class="text-primary">MMK Group 2023</span></p>
        </div>
      </div>
      <!-- fotter end -->
    </div>
  </div>
</template> 
<script>
export default {
  data() {
    return {};
  },
  methods: {},
};
</script>


<style lang="scss" scoped>
</style> 